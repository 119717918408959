export default {
  clientEntityState: 4,
  id: '',
  tenantId: '',
  clientId: '',
  configurationName: '',
  password: '',
  courier: '',
  courierCode: 'pekaes',
  integration: 'PEKAES',
  additionalServices: {},
  shipperId: 0,
  url: '',
  defaultValues: {
    clientEntityState: 4,
    id: '',
    tenantId: '',
    category: 0,
    payerType: '',
    remarks: '',
    stackable: true,
    bypass: '',
    controlCode: '',
    payer: {
      clientEntityState: 4,
      id: '',
      tenantId: '',
      accountNumber: '',
      name: '',
    },
    pickup: {
      clientEntityState: 4,
      id: '',
      tenantId: '',
      suggestedDate: '',
      hourFrom: '',
      hourTo: '',
    },
    delivery: {
      clientEntityState: 4,
      id: '',
      tenantId: '',
      suggestedDate: '',
      hourFrom: '',
      hourTo: '',
    },
    adrInfo: {
      clientEntityState: 4,
      id: '',
      tenantId: '',
      unNo: '',
      name: '',
      labels: '',
      packagingGroup: '',
      tunnelCode: '',
      package: '',
      quantity: 0,
      measureUnit: '',
      weight: 0,
      factor: '',
      classCode: '',
      transportCategory: '',
      addInfo: '',
    },
  },
};
