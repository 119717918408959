<template>
  <v-card elevation="0">
    <v-card-title>
      <v-tooltip
        bottom
        color="black"
      >
        <template v-slot:activator="{on,attrs }">
          <v-icon
            v-bind="attrs"
            class="mr-3"
            v-on="on"
          >
            mdi-help-circle-outline
          </v-icon>
        </template>
        Usługi dodatkowe z domyślnie aktywną wartością
        będą włączone na dokumencie wydania.
      </v-tooltip>

      {{ $t("courierSet.additionalServices").toUpperCase() }}
    </v-card-title>
    <v-card-text>
      <v-divider />
      <div
        v-for="serv in courierAddtionalServices"
        :key="serv.id"
        class="ma-3"
      >
        <service-field
          :id="serv.id"
          :label="serv.name"
          :messages="serv.description"
          :remark="serv.remark"
          :code="serv.serviceId"
          :selected-service="isSelected(serv)"
          :active-service="isActive(serv)"
          :visible-service="isVisible(serv)"
          :parameters="serv.parameters"
          @@activate="activate"
          @@deactivate="deactivate(serv)"
          @@update="updateService"
        >
          <div
            v-if="serv.id === 1"
            class="additional-inputs"
          >
            <text-field
              v-model="accountNumber"
              :title="$t('courierSet.accountNumber')"
              :disabled="!isSelected(serv)"
              :rules="isSelected(serv) ? 'required':''"
              @input="update"
            />
            <text-field
              v-model="name"
              :title="$t('courierSet.name')"
              :disabled="!isSelected(serv)"
              :rules="isSelected(serv) ? 'required':''"
              @input="update"
            />
          </div>
        </service-field>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import ServiceField from '@/components/inputs/ServiceField.vue';
import { mapGetters } from 'vuex';
import { pekaesAdditionalServices as courierAddtionalServices } from '@/constants/Speditors/Pekaes/AddtionalServices.js';
import TextField from '@/components/inputs/TextField.vue';

export default {
  name: 'AdditionalServices',
  components: { ServiceField, TextField },
  props: {
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    additionalServices: [],
    courierAddtionalServices,
    unchangedSet: null,
    id: null,
    tenantId: null,
    accountNumber: null,
    name: null,
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet', 'getOrganization']),
  },
  mounted() {
    if (!this.isNew) {
      this.getDataFromStore();
    }
    this.tenantId = this.getOrganization().id;
    this.unchangedSet = [...this.additionalServices];
  },
  methods: {
    deactivate(service) {
      this.additionalServices.find(
        (x) => x.code === service.serviceId || x.code === service.id,
      ).clientEntityState = 2;
      // tutaj tez id serwisu
      const ser = this.additionalServices.find(
        (x) => x.code === service.serviceId || x.code === service.id,
      );
      if (!ser.id) {
        const idx = this.additionalServices.indexOf(ser);
        this.additionalServices.splice(idx, 1);
      }
      this.update();
    },
    activate({
      isActive, code, isVisible,
    }) {
      this.additionalServices.push(
        {
          clientEntityState: 4,
          tenantId: this.tenantId,
          code,
          isActive,
          isVisible,
        },
      );
      this.update();
    },
    updateService({
      isActive, code, id, isVisible,
    }) {
      this.additionalServices.find(
        (x) => x.code === code || x.code === id,
      ).isActive = isActive;
      this.additionalServices.find(
        (x) => x.code === code || x.code === id,
      ).isVisible = isVisible;
      const state = this.additionalServices.find(
        (x) => x.code === code || x.code === id,
      ).clientEntityState;
      this.additionalServices.find(
        (x) => x.code === code || x.code === id,
      )
        .clientEntityState = state === 4 ? state : 3;
      this.update();
    },
    update() {
      const state = this.additionalServices === this.unchangedSet ? 1 : 3;
      const serviceParameters = {
        additionalServices:
        {
          clientEntityState: this.isNew || !this.id ? 4 : state,
          id: this.id,
          tenantId: this.tenantId,
          services: this.additionalServices,
        },
        payer: {
          clientEntityState: this.isNew || !this.id ? 4 : state,
          accountNumber: this.accountNumber,
          name: this.name,
        },
      };
      this.$emit('@input', serviceParameters);
    },
    getDataFromStore() {
      const { additionalServices, defaultValues } = this.getConfigurationSet();
      this.additionalServices = additionalServices ? additionalServices.services : [];
      this.id = additionalServices ? additionalServices.id : null;
      const { accountNumber, name } = defaultValues.payer;
      this.accountNumber = accountNumber;
      this.name = name;
    },
    isActive(service) {
      return this.additionalServices.find((x) => x.code === service.serviceId
      || x.code === service.id)?.isActive;
    },
    isVisible(service) {
      return this.additionalServices.find((x) => x.code === service.serviceId
      || x.code === service.id)?.isVisible;
    },
    isSelected(service) {
      return !!this.additionalServices.find((x) => x.code === service.serviceId
      || x.code === service.id);
    },
  },
};
</script>
