<template>
  <v-container class="pt-0">
    <v-card
      elevation="0"
    >
      <v-card-title class="pb-0">
        {{ $t("courierSet.prcelContent").toUpperCase() }}
      </v-card-title>
      <v-container>
        <v-row class="pb-5">
          <v-divider />
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <select-field
              v-model="category"
              :title="$t('courierSet.categoryPekaes')"
              :filed-items="categories"
              rules=""
              @input="update"
            />
          </v-col>
          <v-col class="pb-0">
            <select-field
              v-model="payerType"
              :title="$t('courierSet.payerType')"
              :filed-items="payerTypes"
              :hint="$t('courierSet.payerTypeHint')"
              rules=""
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <text-area-field
              v-model="remarks"
              :title=" $t('courierSet.parcelDescription')"
              rules="max:165"
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pt-0">
            <check-field
              v-model="stackable"
              :label="$t('courierSet.stackable')"
              @input="update"
            />
          </v-col>
          <v-col class="pb-0">
            <text-field
              v-model="bypass"
              :title="$t('courierSet.bypass')"
              rules=""
              @input="update"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="pb-0">
            <text-field
              v-model="controlCode"
              :title="$t('courierSet.controlCode')"
              rules=""
              @input="update"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import TextAreaField from '@/components/inputs/TextAreaField.vue';
import { mapGetters } from 'vuex';
import SelectField from '@/components/inputs/SelectField.vue';
import TextField from '@/components/inputs/TextField.vue';
import CheckField from '@/components/inputs/CheckField.vue';

export default {
  components: {
    TextAreaField,
    SelectField,
    TextField,
    CheckField,
  },
  props: {
    isNew: { type: Boolean, default: null },
  },
  data: () => ({
    remarks: null,
    category: 2,
    categories: [
      { id: 1, name: 'Paczkowa' },
      { id: 2, name: 'Paletowa' },
      { id: 3, name: 'Częściówkowa' }],
    payerType: 'sender',
    payerTypes: [
      { id: 'sender', name: 'Nadawca' },
      { id: 'receiver', name: 'Odbiorca' },
      { id: '3part', name: 'Strona trzecia' }],
    stackable: false,
    bypass: null,
    controlCode: null,
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
  },
  created() {
    this.getConfSet();
  },
  methods: {
    update() {
      const parameters = {
        clientEntityState: this.isNew ? 4 : 3,
        remarks: this.remarks,
        category: this.category,
        payerType: this.payerType,
        stackable: this.stackable,
        bypass: this.bypass,
        controlCode: this.controlCode,
      };
      this.$emit('@input', parameters);
    },
    getConfSet() {
      const { defaultValues } = this.getConfigurationSet();
      this.remarks = defaultValues.remarks;
      this.category = defaultValues.category;
      this.payerType = defaultValues.payerType;
      this.stackable = defaultValues.stackable;
      this.bypass = defaultValues.bypass;
      this.controlCode = defaultValues.controlCode;
    },

  },
};
</script>

<style scoped lang="scss">

.col{
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
